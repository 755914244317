<template>
  <section>
    <div class="box">
      <h1 class="title">{{ $t('oidc_verification') }} </h1>
      <div class="notification is-danger" v-if="form_errors.length">
        <button class="delete" @click.prevent="deleteNotification"></button>
        <b>{{ $t('correct_errors') }}</b>
        <ul>
          <li v-for="error in form_errors" :key="error.id">{{ error }}</li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import shared from '../components/shared.js'
export default {
  name: 'OIDC',
  mixins: [shared],
  data() {
    return {
      form_errors: [],
      success: ""
    }
  },
  mounted: function() {
    this.submit(this.$route.query)
    this.success = ""
  },
  methods: {
    ...mapActions(['verifyOIDC']),
    async submit(params) {
      try {
        this.form_errors = []
        await this.verifyOIDC(params)
        this.$router.push('/')
      } catch (error) {
        this.form_errors = ["Token verification failed, please retry. Contact admins if the error persists"] // TODO: use shared.methods.catchError(error)
      }
    },
    async deleteNotification() {
      this.form_errors = []
    }
  }
}
</script>
