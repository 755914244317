<template>
  <section>
    <div class="box" v-if="!otp_secret">
      <h1 class="title">{{ $t('enter_email_pwd') }}</h1>
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <form @submit.prevent="submit">
          <div class="notification is-danger" v-if="form_errors.length">
            <button class="delete" @click.prevent="deleteNotification"></button>
            <b>{{ $t('correct_errors') }}</b>
            <ul>
              <li v-for="error in form_errors" :key="error.id">{{ error }}</li>
            </ul>
          </div>
          <ValidationProvider rules="required|email" name="Email" v-slot="{ errors }" :custom-messages="{ required:$t('field_required'), email: $t('valid_email') }">
            <div class="mb-3">
              <b-field :label="$t('email_address')"
                       :type="{'is-danger': errors[0]}"
                       :message="errors">
                <b-input
                  type="email"
                  v-model="user.email" />
              </b-field>
            </div>
          </ValidationProvider>
          <ValidationProvider :rules="{required: true, regex: /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ !#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d !#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}|.{20,})+$/ }" name="Password" vid="password" v-slot="{ errors }" :custom-messages="{ required:$t('field_required'), regex: $t('pwd_validation') }">
            <div class="mb-3">
              <b-field :label="$t('pwd')"
                       :type="{'is-danger': errors[0]}"
                       :message="errors">
                <b-input
                  type="password"
                  v-model="user.password" />
              </b-field>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required|confirmed:password" name="Password confirmation" v-slot="{ errors }" :custom-messages="{ required:$t('field_required'), confirmed: $t('pwd_mismatch') }">
            <div class="mb-3">
              <b-field :label="$t('confirm_pwd')"
                       :type="{ 'is-danger': errors[0] }"
                       :message="errors">
                <b-input
                  type="password"
                  name="password_confirmation" data-vv-as="password"
                  :error-messages="errors"
                  v-model="confirmPassword"
                  :message="errors" />
              </b-field>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required" name="Terms and Agreements" v-slot="{ errors }" :custom-messages="{ required: $t('terms_and_conditions_msg') }">
            <div class="mb-3">
              <b-field :label="$t('terms_agreements')"
                       :type="{'is-danger': errors[0]}"
                       :message="errors">
                <b-checkbox type="checkbox" v-model="user.isPresent"> {{ $t('agree_text') }} <a href="#" @click="isCardModalActive = true">{{ $t('terms_txt') }}</a></b-checkbox>
              </b-field>
            </div>
          </ValidationProvider>
          <b-button :disabled="invalid" type="is-primary" native-type="submit">{{ $t('register') }}</b-button>
        </form>
      </ValidationObserver>
      <div>
        <section class="section">
          <p><em>{{ $t('note_2fa') }}</em></p>
          <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title">
                  {{ $t('terms_use') }}
                </p>
              </header>
              <div class="card-content">
                <div class="content">
                  <p>{{ $t('content1_register') }}</p>
                  <p>{{ $t('content2_register') }}</p>
                  <ol>
                    <li>{{ $t('li1_register') }}</li>
                    <li>{{ $t('li2_register') }}</li>
                    <li>{{ $t('li3_register') }}</li>
                    <li>{{ $t('li4_register') }}</li>
                    <li>{{ $t('li5_register') }}</li>
                    <li>{{ $t('li6_register') }}</li>
                    <li>{{ $t('li7_register') }}</li>
                    <li>{{ $t('li8_register') }}</li>
                    <li>{{ $t('li9_register') }}</li>
                  </ol>
                  <p>{{ $t('content3_register') }}</p>
                </div>
              </div>
              <footer class="modal-card-foot">
                <b-button
                  label="Close"
                  @click="isCardModalActive = false" />
              </footer>
            </div>
          </b-modal>
        </section>
      </div>
    </div>

    <div class="box" v-if="otp_secret">
      <h1 class="title">{{ $t('scan_barcode') }}</h1>
      <p>{{ $t('scan_instructions') }} </p>
      <div class="columns is-centered mt-2 mb-2">
        <div class="column is-narrow">
          <qrcode-vue :value="qrvalue" :size="size" level="H" />
        </div>
      </div>
      <b-collapse
        :open="false"
        position="is-top"
        aria-id="totpsecret">
        <template #trigger="props">
          <a aria-controls="totpsecret">
            {{ !props.open ? $t('manually_2fa_click') : 'Hide' }}
          </a>
        </template>
        {{ $t('manually_2fa') }}
        <b-message type="is-warning mt-2">
          {{ otp_secret }}
        </b-message>
      </b-collapse>
      <h1 class="title" style="padding-top:20px">{{ $t('email_ver_step') }}</h1>
      <div class="mt-2 mb-2">
        {{ $t('complete_registration') }}
      </div>
      <b-button @click="clickComplete" type="is-primary">{{ $t('email_verified_check') }}</b-button>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import QrcodeVue from 'qrcode.vue'
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full'
import shared from '../components/shared.js'
export default {
  name: 'Register',
  components: {
    QrcodeVue,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [shared],
  data() {
    return {
      user: {
        email: '',
        password: '',
        isPresent: [],
      },
      confirmPassword: '',
      form_errors: [],
      size: 300,
      isCardModalActive: false,
    }
  },
  computed: {
    ...mapGetters({otp_secret: 'stateOTPSecret' }),
    qrvalue: function () {
      return `otpauth://totp/${encodeURIComponent('SecureNed')}:${encodeURIComponent(this.user.email)}?secret=${encodeURIComponent(this.otp_secret)}&issuer=${encodeURIComponent('SecureNed')}&digits=6&period=30&algorithm=SHA1`
    }
  },
  methods: {
    clickComplete() {
      this.$router.push('/login')
    },
    ...mapActions(['register']),
    async submit() {
      try {
        this.form_errors = []
        await this.register(this.user)
      } catch (error) {
        this.form_errors = shared.methods.catchError(error)
      }
    },
    async deleteNotification() {
      this.form_errors = []
    }
  },
}
</script>
