<template>
  <section>
    <div class="box">
      <div v-if="!confirmResetVisible">
        <h3 class="title">{{ $t('pwd_reset') }}</h3>
        <ValidationObserver ref="observer" v-slot="{ invalid }">
          <form @submit.prevent="submit">
            <div class="notification is-danger" v-if="form_errors.length">
              <button class="delete" @click.prevent="deleteNotification"></button>
              <b>{{ $t('correct_errors') }}</b>
              <ul>
                <li v-for="error in form_errors" :key="error.id">{{ error }}</li>
              </ul>
            </div>
            <ValidationProvider :rules="{required: true, regex: /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ !#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d !#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}|.{20,})+$/ }" name="Password" vid="password" v-slot="{ errors }" :custom-messages="{required:$t('field_required'), regex: $t('pwd_validation') }">
              <div class="mb-3">
                <b-field :label="$t('new_pwd')"
                         :type="{'is-danger': errors[0]}"
                         :message="errors">
                  <b-input
                    type="password"
                    v-model="form.password" />
                </b-field>
              </div>
            </ValidationProvider>
            <ValidationProvider rules="required|confirmed:password" name="Password confirmation" v-slot="{ errors }" :custom-messages="{ required:$t('field_required'), confirmed: 'The given passwords do not match' }">
              <div class="mb-3">
                <b-field label="Confirm password"
                         :type="{ 'is-danger': errors[0] }"
                         :message="errors">
                  <b-input
                    type="password"
                    name="password_confirmation" data-vv-as="password"
                    :error-messages="errors"
                    v-model="form.confirmPassword"
                    :message="errors" />
                </b-field>
              </div>
            </ValidationProvider>
            <b-button :disabled="invalid" type="is-primary" native-type="submit">{{ $t('submit') }}</b-button>
          </form>
        </ValidationObserver>
        <div>
          <section class="section">
            <p><em>{{ $t('lost_2fa') }}</em></p>
          </section>
        </div>
      </div>
      <div v-if="confirmResetVisible">
        <h1 class="title">{{ $t('pwd_reset') }}</h1>
        <p>{{ $t('succ_pwd_reset') }} <router-link :to="{ name: 'Login' }">{{ $t('here') }}</router-link> {{ $t('return_to_login') }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import shared from '../components/shared.js'
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full'
export default {
  name: 'Reset',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [shared],
  data() {
    return {
      form: {
        token: '',
        password: '',
        confirmPassword: '',
      },
      form_errors: [],
      confirmResetVisible: false,
    }
  },
  methods: {
    ...mapActions(['newPassword']),
    async submit() {
      try {
        this.form_errors = []
        this.form.token = this.$route.query.token
        await this.newPassword(this.form)
        this.confirmResetVisible = true
      } catch (error) {
        this.form_errors = shared.methods.catchError(error)
      }
    },
    async deleteNotification() {
      this.errors = []
    }
  },
}
</script>
