import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from 'vee-validate/dist/locale/en'
import nl from 'vee-validate/dist/locale/nl'

Vue.use(VueI18n)
export const i18n = new VueI18n({
  locale: 'nl',
  fallbackLocale: 'en',
  messages: {
    en: {
      agree_text: 'I agree to the',
      already_account: 'If you already have an account, please click',
      bc_to_login: 'Back to login form',
      complete_registration: 'After you have completed the registration of your two-factor authentication, please first open your email box and visit the link in the activation email. Once you have verified your email address, you can click the button below (or use the the navigation bar above) to be redirected to the login page.',
      confirm_pwd: 'Confirm password: ',
      content1_register: 'Since the confidentiality and integrity of SecureNed depends in part on the security measures at our participants, we kindly ask you to carefully read the following Terms of Use.',
      content2_register: 'By registering through the creation of an account on National Cyber Security Center (NCSC) SecureNed, you explicitly agree to:',
      content3_register: 'NCSC reserves the right to block an account temporarily or permanently, for example, when a user acts in violation of these Terms of Use in the opinion of NCSC. When this happens, NCSC will explicitly notify you with a description of the observed misuses. NCSC collects information about the use of SecureNed, in particular which account logs in at which point of time using which IP-address.',
      correct_errors: 'Please correct the following error(s):',
      email_address: 'Email address:',
      email_ver_step: '3. Email verification',
      email_verification: 'Email verification...',
      email_verified_check: 'I have verified my email address (return to login page)',
      enter_email_pwd: '1. Enter your email address and password',
      field_required: 'This field is required',
      hello: 'Hello',
      here: 'here',
      here_to_login: 'here to log in',
      here_to_register: 'here to register',
      home: 'Home',
      invite_to_register: 'If you have been invited to register yourself, please click',
      li1_register: 'provide accurate, current, and complete user information as requested during registration;',
      li2_register: 'promptly update this information in the event of any changes;',
      li3_register: 'set a strong password (as described in the registration process);',
      li4_register: 'keep this password secure, preferably in a password manager approved by your organization;',
      li5_register: 'register a second-factor for authentication by using a Time-Based One Time Password (TOTP);',
      li6_register: 'not share your registration information with third parties, such as colleagues within or parties outside of your organization;',
      li7_register: 'report theft or loss of your registration data (password, mobile device with TOTP application) as soon as possible, within 24 hours of the event at the latest;',
      li8_register: 'not distribute spam, viruses, malware or other inappropriate messages or information through SecureNed;',
      li9_register: 'not circumvent or interfere with the security of our SecureNed.',
      login: 'Login',
      logout: 'Log out',
      lost_2fa: 'Note: if you have lost access to your two-factor token, you cannot use this form. Contact us instead.',
      manually_2fa: 'If you are not able to scan the QR Code, you can add this account to your two-factor authentication app by entering the following OTP secret key manually:',
      manually_2fa_click: 'Click here if you are not able to scan the QR code',
      new_pwd: 'Please enter your new password below:',
      no_email_update_msg: 'Please note: you cannot change your email address since only specific whitelisted email addresses are allowed to use the application. If you require a change, please contact the SecureNed admins.',
      note_2fa: 'Note: after registration you will be presented with a QR code to scan as two-factor authentication. Please ensure that you have a mobile app ready for this purpose, for example, FreeOTP, Google Authenticator or Microsoft Authenticator.',
      oidc_verification: 'Verifying single sign-on token...',
      prof_updated: 'Profile was updated successfully',
      profile: 'Profile',
      pwd: 'Password:',
      pwd_forgot: 'Forgotten password?',
      pwd_mismatch: 'The given passwords do not match',
      pwd_reset: 'Reset your password',
      pwd_reset_to_email: 'If this email address exists, a password reset email has been sent. Click',
      pwd_validation: 'Either a password of at least 8 characters with at minimum one letter, one number and one special character, or a passphrase of at least 20 characters',
      register: 'Register',
      return_homepage: 'to return to the homepage.',
      return_to_login: 'to return to the login page',
      scan_barcode: '2. Scan this barcode with your app',
      scan_instructions: 'Scan the image below with a two-factor authentication app on your phone (e.g. FreeOTP, Google Authenticator or Microsoft Authenticator).',
      submit: 'Submit',
      succ_email_verification: 'Your email address was successfully verified! You can now login',
      succ_pwd_reset: 'The password has been reset successfully. Click',
      terms_agreements: 'Terms and Agreements',
      terms_and_conditions_msg: 'You need to agree with terms and agreements',
      terms_txt: 'terms of use for SecureNed',
      terms_use: 'Terms of use SecureNed',
      to_home: 'Return to home.',
      update_prof: 'Update your profile',
      username: 'Username:',
      valid_email: 'Please provide a valid email address',
      welcome_msg: 'Welcome to SecureNed',
      validation: en.messages
    },
    nl: {
      agree_text: 'Ik ga akkoord met de',
      already_account: 'Als u al een account heeft, klik dan',
      bc_to_login: 'Terug naar inlogformulier',
      complete_registration: 'Nadat u de registratie van uw tweefactorauthenticatie heeft voltooid, opent u eerst uw e-mailbox en bezoekt u de link in de activeringsmail. Nadat u uw e-mailadres heeft geverifieerd, kunt u op de onderstaande knop klikken (of de navigatiebalk hierboven gebruiken) om doorgestuurd te worden naar de inlogpagina.',
      confirm_pwd: 'Wachtwoord bevestigen: ',
      content1_register: 'Omdat de veiligheid en integriteit van SecureNed mede afhankelijk is van de beveiligingsinspanning van onze deelnemers, vragen wij u bij deze registratie de onderstaande Gebruiksvoorwaarden goed te lezen.',
      content2_register: 'Door u te registreren door middel van het aanmaken van een account op Nationaal Cyber Security Centrum (NCSC) SecureNed gaat u uitdrukkelijk akkoord om:',
      content3_register: 'NCSC behoudt te allen tijde het recht een account tijdelijk of permanent te blokkeren, bijvoorbeeld wanneer een gebruiker naar het oordeel van NCSC in strijd handelt met deze Gebruiksvoorwaarden. Wanneer hier sprake van is, zal NCSC dit expliciet melden met een onderbouwing van de onregelmatige gedragingen. NCSC verzamelt informatie over gebruik van SecureNed, specifiek welke gebruikersaccount inlogt op welk tijdstip vanaf welk IP-adres.',
      correct_errors: 'Corrigeer de volgende fout(en):',
      email_address: 'E-mailadres:',
      email_ver_step: '3. E-mailverificatie',
      email_verification: 'E-mail verificatie...',
      email_verified_check: 'Ik heb mijn e-mailadres geverifieerd (ga terug naar de inlogpagina)',
      enter_email_pwd: '1. Voer uw e-mailadres en wachtwoord in',
      field_required: 'Dit veld is verplicht',
      hello: 'Hallo',
      here: 'hier',
      here_to_login: 'hier om in te loggen',
      here_to_register: 'hier om te registreren',
      home: 'Startpagina',
      invite_to_register: 'Als u bent uitgenodigd om u te registreren, klik dan',
      li1_register: 'correcte, actuele en volledige gebruikersinformatie te verstrekken zoals gevraagd bij de registratie;',
      li2_register: 'deze informatie bij eventuele wijzigingen onmiddellijk te actualiseren;',
      li3_register: 'een sterk wachtwoord in te stellen (zoals beschreven in het registratieproces);',
      li4_register: 'dit wachtwoord veilig te bewaren, bij voorkeur in een door uw organisatie goedgekeurde password manager;',
      li5_register: 'een tweede-factor voor authenticatie te registreren door middel van een Time-Based One Time Password (TOTP);',
      li6_register: "uw registratie gegevens niet te delen met derden, zoals collega's binnen uw organisatie of partijen buiten uw organisatie;",
      li7_register: 'diefstal of verlies van uw registratie gegevens (wachtwoord, mobiel apparaat met TOTP applicatie) direct, doch uiterlijk binnen 24 uur na het constateren van de vermissing, te melden;',
      li8_register: 'geen spam, virussen, malware of andere ongepaste berichten of informatie verspreiden via SecureNed;',
      li9_register: 'de beveiliging van SecureNed niet te ontwijken of te hinderen.',
      login: 'Inloggen',
      logout: 'Uitloggen',
      lost_2fa: 'Let op: als u geen toegang meer heeft tot uw two-factor token, kunt u dit formulier niet gebruiken. Neem in plaats daarvan contact met ons op.',
      manually_2fa: 'Indien u de QR-code niet kunt scannen, kunt u dit account toevoegen aan uw tweefactorauthenticatie-app door de volgende geheime OTP-sleutel handmatig in te voeren:',
      manually_2fa_click: 'Klik hier als u niet in staat bent de QR-code te scannen',
      new_pwd: 'Voer hieronder uw nieuwe wachtwoord in:',
      no_email_update_msg: 'Let op: u kunt uw e-mailadres niet wijzigen, aangezien alleen specifieke goedgekeurde e-mailadressen de applicatie mogen gebruiken. Als u een wijziging nodig heeft, neem dan contact op met de SecureNed-beheerders.',
      note_2fa: 'Let op: na registratie ontvangt u een QR-code om te scannen als tweefactorauthenticatie. Zorg ervoor dat u hiervoor een mobiele app klaar heeft staan, bijvoorbeeld FreeOTP, Google Authenticator of Microsoft Authenticator.',
      oidc_verification: 'Uw single sign-on token wordt geverifieerd...',
      prof_updated: 'Profiel is succesvol bijgewerkt',
      profile: 'Profiel',
      pwd: 'Wachtwoord:',
      pwd_forgot: 'Wachtwoord vergeten?',
      pwd_mismatch: 'De opgegeven wachtwoorden komen niet overeen',
      pwd_reset: 'Stel uw wachtwoord opnieuw in',
      pwd_reset_to_email: 'Als dit e-mailadres bestaat, is er een e-mail voor het opnieuw instellen van het wachtwoord verzonden. Klik',
      pwd_validation: 'Ofwel een wachtwoord van minimaal 8 tekens met minimaal één letter, één cijfer en één speciaal teken, ofwel een wachtwoordzin van minimaal 20 tekens',
      register: 'Registreren',
      return_homepage: 'om terug te keren naar de startpagina.',
      return_to_login: 'om terug te keren naar de inlogpagina',
      scan_barcode: '2. Scan deze barcode met uw app',
      scan_instructions: 'Scan de onderstaande afbeelding met een tweefactorauthenticatie-app op uw telefoon (bijvoorbeeld FreeOTP, Google Authenticator of Microsoft Authenticator).',
      submit: 'Indienen',
      succ_email_verification: 'Uw e-mailadres is succesvol geverifieerd! U kunt nu inloggen',
      succ_pwd_reset: 'Het wachtwoord is succesvol opnieuw ingesteld. Klik',
      terms_agreements: 'Voorwaarden',
      terms_and_conditions_msg: 'U moet akkoord gaan met voorwaarden',
      terms_txt: 'gebruiksvoorwaarden voor SecureNed',
      terms_use: 'Gebruiksvoorwaarden SecureNed',
      to_home: 'Keer terug naar de startpagina.',
      update_prof: 'Update uw profiel',
      username: 'Gebruikersnaam:',
      valid_email: 'Voer alstublieft een geldig e-mailadres in',
      welcome_msg: 'Welkom bij SecureNed',
      validation: nl.messages
    }
  }
})
