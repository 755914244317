<template>
  <section>
    <div class="box" v-if="!result.length">
      <h1 class="title">{{ $t('update_prof') }}</h1>
      <form @submit.prevent="submit">
        <div class="notification is-danger" v-if="form_errors.length">
          <button class="delete" @click.prevent="deleteNotification"></button>
          <b>{{ $t('correct_errors') }}</b>
          <ul>
            <li v-for="error in form_errors" :key="error.id">{{ error }}</li>
          </ul>
        </div>
        <div class="mb-3">
          <b-field label="Email:">
            <b-input type="email" disabled v-model="user.email" />
          </b-field>
        </div>
        <ValidationObserver ref="observer" v-slot="{ invalid }" v-if="!user.oidc">
          <ValidationProvider :rules="{required: true, regex: /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ !#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d !#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}|.{20,})+$/ }" name="Password" vid="password" v-slot="{ errors }" :custom-messages="{ required:$t('field_required'), regex: $t('pwd_validation') }">
            <div class="mb-3">
              <b-field :label="$t('pwd')"
                       :type="{'is-danger': errors[0]}"
                       :message="errors">
                <b-input
                  type="password"
                  v-model="user.password" />
              </b-field>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required|confirmed:password" name="Password confirmation" v-slot="{ errors }" :custom-messages="{ required:$t('field_required'), confirmed: 'The given passwords do not match' }">
            <div class="mb-3">
              <b-field label="Confirm password"
                       :type="{ 'is-danger': errors[0] }"
                       :message="errors">
                <b-input
                  type="password"
                  name="password_confirmation" data-vv-as="password"
                  :error-messages="errors"
                  v-model="user.confirmPassword"
                  :message="errors" />
              </b-field>
            </div>
          </ValidationProvider>
          <b-button :disabled="invalid" type="is-primary" native-type="submit">{{ $t('submit') }}</b-button>
        </ValidationObserver>
      </form>
      <div>
        <section class="section">
          <p><em>{{ $t('no_email_update_msg') }}</em></p>
        </section>
      </div>
    </div>

    <div class="box" v-if="result.length">
      <h1 class="title">{{ $t('update_prof') }}</h1>
      <div>
        <section class="section">
          <p>{{ $t('prof_updated') }} &mdash; <router-link :to="{ name: 'Home' }">{{ $t('to_home') }}</router-link></p>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import shared from '../components/shared.js'
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full'
export default {
  name: 'Profile',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [shared],
  data() {
    return {
      form_errors: [],
      result: [],
    }
  },
  computed: {
    ...mapGetters({user: 'stateUser'}),
  },
  created: function() {
    return this.$store.dispatch('viewMe')
  },
  methods: {
    ...mapActions(['updateProfile']),
    async submit() {
      try {
        this.form_errors = []
        await this.updateProfile(this.user)
        this.result = ["Profile updated"]
      } catch (error) {
        this.form_errors = shared.methods.catchError(error)
      }
    },
    async deleteNotification() {
      this.form_errors = []
    }
  }
}
</script>
