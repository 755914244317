import axios from 'axios'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Buefy from 'buefy'
import './assets/ncsc_bulma.css'
import { i18n } from './plugins/i18n'
import "./assets/bulma-divider.css"

const UNAUTHORIZED = 401
axios.defaults.withCredentials = true
axios.defaults.baseURL = "VUE_APP_STANDALONE_BACKEND_URL" in process.env ? process.env.VUE_APP_STANDALONE_BACKEND_URL : 'https://securened.ncsc.nl/backend/'
Vue.config.productionTip = false

Vue.use(Buefy)

new Vue({
  router,
  store,
  i18n,
  mounted() {
    // test if we still have an open session
    store.dispatch("testAuthStatus")
      // eslint-disable-next-line no-unused-vars
      .catch(error => {
        // Any errors are likely normal (e.g. not logged in). Therefore, supress here
      })

    // handle session expirations
    axios.interceptors.response.use(
      response => response,
      error => {
        const {status} = error.response
        if (status === UNAUTHORIZED) {
          alert("Your session expired, please log in again.")
          store.commit("logout", null)
          router.push("/login")
        }
        return Promise.reject(error)
      }
    )
  },
  render: h => h(App),
}).$mount('#app')
