import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Register from '@/views/Register.vue'
import Login from '@/views/Login.vue'
import Reset from '@/views/Reset.vue'
import Profile from '@/views/Profile.vue'
import Verify from '@/views/Verify.vue'
import OIDC from '@/views/OIDC.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/reset',
    name: 'Reset',
    component: Reset,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/verify',
    name: 'Verify',
    component: Verify,
  },
  {
    path: "/.well-known/security.txt",
    beforeEnter() {
      window.location.href = "https://ncsc.nl/.well-known/security.txt";
    }
  },
  {
    path: '/oidc',
    name: 'OIDC',
    component: OIDC,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
