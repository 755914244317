<template>
  <section>
    <div class="box">
      <h1 class="title">{{ $t('email_verification') }} </h1>
      <div class="notification is-danger" v-if="form_errors.length">
        <button class="delete" @click.prevent="deleteNotification"></button>
        <b>{{ $t('correct_errors') }}</b>
        <ul>
          <li v-for="error in form_errors" :key="error.id">{{ error }}</li>
        </ul>
      </div>
      <div class="notification is-success" v-if="success">
        <p>{{ success }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import shared from '../components/shared.js'
export default {
  name: 'Verify',
  mixins: [shared],
  data() {
    return {
      form_errors: [],
      success: ""
    }
  },
  mounted: function() {
    // FIXME: create an error if query parameter not present
    this.submit(this.$route.query.token)
    this.success = ""
  },
  methods: {
    ...mapActions(['verify']),
    async submit(token) {
      try {
        this.form_errors = []
        await this.verify(token)
        this.success = this.$t("succ_email_verification")
      } catch (error) {
        this.form_errors = shared.methods.catchError(error)
      }
    },
    async deleteNotification() {
      this.form_errors = []
    }
  }
}
</script>
