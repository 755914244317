<template>
  <section>
    <div v-if="isAuthenticated">
    </div>
    <div v-else>
      <div class="box">
        <h1 class="title">{{ $t('welcome_msg') }}</h1>
        <div class="mt-2 mb-2">
          {{ $t('invite_to_register') }} <span><router-link :to="{ name: 'Register' }">{{ $t('here_to_register') }}</router-link>.</span>
        </div>
        <div class="mt-2 mb-2">
          {{ $t('already_account') }} <span><router-link :to="{ name: 'Login' }">{{ $t('here_to_login') }}</router-link>.</span>
        </div>
      </div>
    </div>
    <div v-if="isAuthenticated">
      <div id="magic"></div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

function loadBastion() {
  if (this.isAuthenticated) {
    if (document.getElementById('bootstrap')) {
      window.location.reload()
      return
    }
    document.getElementById("navbar-home").onclick = function() { window.location.reload() }

    axios.get('/js/bastion_verification_keys.json', {baseURL: process.env.BASE_URL}).then(async(response) => {
      let def_root_verification_keys = response.data.root_verification_keys
      response = await axios.get('/js/bastion_subresource_integrity.json', {baseURL: process.env.BASE_URL})
      let def_main_subresource_integrity = response.data.main_subresource_integrity

      // Load trustee verification keys
      let root_verification_keys = document.createElement('script')
      root_verification_keys.id = 'root_verification_keys'
      root_verification_keys.type = 'application/json'
      root_verification_keys.appendChild(document.createTextNode(def_root_verification_keys))
      document.head.appendChild(root_verification_keys)

      // Load the boostrap code while verifying the integrity
      let bastion_base_url = "VUE_APP_BASTION_URL" in process.env ? process.env.VUE_APP_BASTION_URL : 'https://securened.ncsc.nl/bastion'
      let script = document.createElement('script')
      script.src = `${bastion_base_url}/get/main.js`
      script.integrity = def_main_subresource_integrity
      script.setAttribute('id', 'bootstrap')
      document.head.appendChild(script)
    }).catch(function (error){
      console.log(error)
      alert("Failed to retrieve a necessary Bastion resource. Please contact admins")
    })
  }
}

import { mapGetters } from 'vuex'
export default {
// @ is an alias to /src
  name: 'Home',
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
  mounted: loadBastion,
  updated: loadBastion,
}
</script>
