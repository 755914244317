import axios from 'axios'

const state = {
  user: null,
  otp_secret: null,
}

const getters = {
  isAuthenticated: state => !!state.user,
  stateUser: state => state.user,
  stateOTPSecret: state => state.otp_secret,
}

const actions = {
  async register({commit}, form) {
    let {data} = await axios.post('auth/register', form)
    await commit('setOTPSecret', data.otp_secret)
    await axios.post('auth/request-verify-token', {"email": form.email}) // request email verification (otherwise no login allowed)
  },
  // eslint-disable-next-line no-empty-pattern
  async passwordReset({}, form) {
    await axios.post('auth/forgot-password', form)
  },
  // eslint-disable-next-line no-empty-pattern
  async newPassword({}, form) {
    await axios.post('auth/reset-password', form)
  },
  async updateProfile({commit}, form) {
    await axios.patch('users/me', form)

    // fetch updated profile
    let {data} = await axios.get('users/me')
    await commit('setUser', data)
  },
  async logIn({dispatch}, user) {
    await axios.post('auth/jwt/login', user)
    await dispatch('viewMe')
  },
  async testAuthStatus({commit}) {
    let {data} = await axios.get('users/me')
    return commit('setUser', data)
  },
  async viewMe({commit}) {
    let {data} = await axios.get('users/me')
    await commit('setUser', data)
  },
  async logOut({commit}) {
    commit('logout')
    await axios.post('auth/jwt/logout')
  },
  async verify({dispatch}, token) {
    await axios.post('auth/verify', {"token": token})
    await dispatch('/login')
  },
  // eslint-disable-next-line no-empty-pattern
  async logInOIDC({}) {
    let {data} = await axios.get('auth/oauth/authorize?authentication_backend=cookie')
    return data["authorization_url"]
  },
  async verifyOIDC({dispatch}, params) {
    await axios.get('/auth/oauth/callback', {params})
    await dispatch('viewMe')
  }
}

const mutations = {
  setUser(state, data) {
    state.user = data
  },
  setOTPSecret(state, otp_secret) {
    state.otp_secret = otp_secret
  },
  logout(state) {
    state.user = null
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
