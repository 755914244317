export default {
  methods:{
    catchError(error) {
      if (error.response) {
        if (typeof error.response.data["detail"] === "string") {
          switch (error.response.data["detail"]) {
          case "LOGIN_BAD_CREDENTIALS":
            this.errors = [`You provided incorrect credentials (either wrong email address, password, or two-factor code). Please check your input and try again.`]
            break
          case "LOGIN_USER_NOT_VERIFIED":
            this.errors = [`Your email address is not yet verified. Please check your email and try again.`]
            break
          case "REGISTER_USER_ALREADY_EXISTS":
            this.errors = [`Your account is already registered. You can reset your password if you forgot your credentials.`]
            break
          default:
            this.errors = ["Form error: " + error.response.data["detail"]]
          }
        }
        else if ("code" in error.response.data["detail"]) {
          this.errors = ["Form error: " + error.response.data["detail"]["code"] + " - " + error.response.data["detail"]["reason"]]
        }
        else {
          this.errors = ["Form error: " + error.response.data["detail"][0]["msg"]]
        }
      } else if (error.request) {
        this.errors = ["Request error: " + error.request]
      }
      else {
        this.errors = ["Failed to establish connection to server (please check your internet connection). Contact the server admins if this problem persists"]
      }
      return this.errors
    }
  }
}
