<template>
  <section>
    <div class="box">
      <div v-if="formLoginVisible">
        <h1 class="title">{{ $t('login') }}</h1>
        <ValidationObserver ref="observer" v-slot="{ invalid }">
          <div class="notification is-danger" v-if="form_errors.length">
            <button class="delete" @click.prevent="deleteNotification"></button>
            <b>{{ $t('correct_errors') }}</b>
            <ul>
              <li v-for="error in form_errors" :key="error.id">{{ error }}</li>
            </ul>
          </div>
          <form @submit.prevent="submit">
            <ValidationProvider rules="required|email" name="email" v-slot="{ errors }" :custom-messages="{ required:$t('field_required'), email: $t('valid_email') }">
              <div class="mb-3">
                <b-field :label="$t('username')"
                         :type="{'is-danger': errors[0]}"
                         :message="errors">
                  <b-input
                    type="email"
                    v-model="form.username" />
                </b-field>
              </div>
            </ValidationProvider>
            <ValidationProvider rules="required" name="Password" v-slot="{ errors }" :custom-messages="{ required:$t('field_required')}">
              <div class="mb-3">
                <b-field :label="$t('pwd')"
                         :type="{'is-danger': errors[0]}"
                         :message="errors">
                  <b-input
                    type="password"
                    v-model="form.password" />
                </b-field>
              </div>
            </ValidationProvider>
            <ValidationProvider rules="required" name="TOTP Code" v-slot="{ errors }" :custom-messages="{ required:$t('field_required')}">
              <div class="mb-3">
                <b-field label="TOTP Code:"
                         :type="{'is-danger': errors[0]}"
                         :message="errors">
                  <b-input
                    v-model="form.totp" />
                </b-field>
              </div>
            </ValidationProvider>
            <div class="columns is-vcentered">
              <div class="column is-narrow"><b-button :disabled="invalid" type="is-primary" native-type="submit">{{ $t('submit') }}</b-button></div>
              <div class="column"><a href="#" @click.prevent="showResetForm">{{ $t('pwd_forgot') }}</a></div>
            </div>
          </form>
        </ValidationObserver>
        <div v-if="OIDCVisible">
          <div class="divider">{{ $t('OR') }}</div>
          <p></p>
          <p><b-button type="is-secondary is-rounded" @click.prevent="submitOIDC">{{ $t('Log-in with') }} &nbsp; <img src="../assets/eherkenning.svg" width="55" /></b-button></p>
        </div>
      </div>
      <div v-if="formResetVisible">
        <h1 class="title">{{ $t('pwd_reset') }}</h1>
        <div class="notification is-danger" v-if="form_errors.length">
          <button class="delete" @click.prevent="deleteNotification"></button>
          <b>{{ $t('correct_errors') }}</b>
          <ul>
            <li v-for="error in form_errors" :key="error.id">{{ error }}</li>
          </ul>
        </div>
        <form @submit.prevent="submitReset">
          <div class="mb-3">
            <b-field label="Email:">
              <b-input type="email" required v-model="formReset.email" />
            </b-field>
          </div>
          <b-button type="is-primary" native-type="submit">{{ $t('submit') }}</b-button>
        </form>
        <br />
        <p><a href="#" @click.prevent="showLoginForm">{{ $t('bc_to_login') }}</a></p>
      </div>
      <div v-if="confirmResetVisible">
        <h1 class="title">{{ $t('pwd_reset') }}</h1>
        <p>{{ $t('pwd_reset_to_email') }} <router-link :to="{ name: 'Home' }">{{ $t('here') }}</router-link> {{ $t('return_homepage') }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full'
import shared from '../components/shared.js'
export default {
  name: 'Login',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [shared],
  data() {
    return {
      form: {
        username: '',
        password: '',
        totp: '',
      },
      formReset: {
        email: ''
      },
      form_errors: [],
      formLoginVisible: true,
      formResetVisible: false,
      confirmResetVisible: false,
      OIDCVisible: false,
    }
  },
  mounted: function() {
    this.OIDCVisible = "VUE_APP_OIDC_ENABLED" in process.env ? (Number(process.env.VUE_APP_OIDC_ENABLED) == 1) : false
  },
  methods: {
    ...mapActions(['logIn', 'passwordReset', 'logInOIDC']),
    async submit() {
      const User = new FormData()
      User.append('username', this.form.username)
      User.append('password', this.form.password + this.form.totp)
      try {
        await this.logIn(User)
        this.$router.push('/')
      } catch (error) {
        this.form_errors = shared.methods.catchError(error)
      }
    },
    async submitReset() {
      try {
        this.form_errors = []
        await this.passwordReset({'email': this.formReset.email})
        this.formLoginVisible = false
        this.formResetVisible = false
        this.confirmResetVisible = true
      } catch (error) {
        this.form_errors = shared.methods.catchError(error)
      }
    },
    async showResetForm(event) {
      if (event) {
        this.formLoginVisible = false
        this.formResetVisible = true
      }
    },
    async showLoginForm(event) {
      if (event) {
        this.formLoginVisible = true
        this.formResetVisible = false
      }
    },
    async deleteNotification() {
      this.form_errors = []
    },
    async submitOIDC() {
      const url = await this.logInOIDC()
      window.location.href = url
    }
  }
}
</script>
