<template>
  <div id="app">
    <NavBar />
    <div class="container mt-5 mb-5">
      <router-view />
    </div>
    <FooterComponent />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'
import FooterComponent from '@/components/Footer.vue'
export default {
  components: {
    NavBar,
    FooterComponent
  }
}
</script>
