<template>
  <header>
    <section>
      <div class="columns is-centered">
        <div class="column is-offset-4">
          <div class="container">
            <router-link to="/">
              <img class="pl-6" src="../assets/logo-ncsc.svg"
                   alt="Nationaal Cyber Security Centrum - Ministerie van Justitie en Veiligheid" />
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-primary is-small">
      <div class="hero-head">
        <div class="container pl-3 pr-3">
          <b-navbar class="subtitle is-4">
            <template #start>
              <b-navbar-item tag="router-link" :to="{ path: '/' }" id="navbar-home">
                {{ $t('home') }}
              </b-navbar-item>
              <b-navbar-item v-if="isLoggedIn" tag="router-link" :to="{ path: '/profile' }">
                {{ $t('profile') }}
              </b-navbar-item>
              <!-- <b-navbar-item>
                <div id="switcher">
                  <select v-model="$i18n.locale">
                    <option
                      v-for="(lang, i) in langs"
                      :key="`lang-${i}`"
                      :value="lang">
                      {{ lang }}
                    </option>
                  </select>
                </div>
              </b-navbar-item>
              -->
            </template>
            <template #end>
              <b-navbar-item class="end-element" v-if="isLoggedIn" @click="logout">
                <div class="buttons">
                  <a class="button is-light">
                    {{ $t('logout') }}
                  </a>
                </div>
              </b-navbar-item>
              <b-navbar-item v-if="!isLoggedIn" tag="router-link" :to="{ path: '/register' }">
                {{ $t('register') }}
              </b-navbar-item>
              <b-navbar-item class="end-element" v-if="!isLoggedIn" tag="router-link" :to="{ path: '/login' }">
                {{ $t('login') }}
              </b-navbar-item>
            </template>
          </b-navbar>
        </div>
      </div>
    </section>
  </header>
</template>

<script>
export default {
  name: 'NavBar',
  data(){
    return {langs: ['en', 'nl']}
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isAuthenticated
    }
  },
  methods: {
    async logout () {
      await this.$store.dispatch('logOut')
      this.$router.push('/login')
    }
  },
}
</script>
